// src/providers/DefaultProvider.tsx
// TODO: Switch to Plausible Analytics and pause Google Tag Manager -- npm package available for Plausible + Next.js 14
"use client";

import ErrorProvider from "@/providers/ErrorProvider";
import { makeStore } from "@/store/index";
import "@/styles/globals.css";
import theme from "@/styles/theme";
import { sentryFeedbackHandler } from "@/utils/sentryFeedback";
import PlausibleAnalytics from "@/utils/analytics/plausibleAnalytics";
import UmamiAnalytics from "@/utils/analytics/umamiAnalytics";
import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import localFont from "next/font/local";
import { usePathname } from "next/navigation";
import Script from "next/script";
import { useEffect, useRef } from "react";
import { Provider } from "react-redux";
import { Store } from '@reduxjs/toolkit';
import { ENVIRONMENT_CONFIGS } from "../../env_config";
import styles from './DefaultProvider.module.css';

const helvetica = localFont({
  src: [
    {
      path: "../styles/fonts/HelveticaLTPro-Roman.otf",
      weight: "400",
    },
    {
      path: "../styles/fonts/HelveticaLTPro-Bold.otf",
      weight: "700",
    },
  ],
});

interface DefaultProviderProps {
  children: React.ReactNode;
  session: Session | null;
}

export function DefaultProvider({ children, session }: DefaultProviderProps) {
  const pathname = usePathname();
  const storeRef = useRef<Store>();

  if (!storeRef.current) {
    storeRef.current = makeStore();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      (() => {
        "use strict";
        var t: ParentNode | null,
          e = {
            appId: ENVIRONMENT_CONFIGS.misc.atlasAppId,
            v: 2,
            q: [] as any[],
            call: function () {
              this.q.push([...arguments]);
            },
          };
        (window as any).Atlas = e;
        var n = document.createElement("script");
        (n.async = !0),
          (n.src = "https://app.atlas.so/client-js/atlas.bundle.js");
        var s = document.getElementsByTagName("script")[0];
        null === (t = s.parentNode) || void 0 === t || t.insertBefore(n, s);
      })();

      (window as any).Atlas.call("start", {
        callbacks: {
          sentry: () => sentryFeedbackHandler(),
        },
      });
    }, 10);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`${helvetica.className} ${styles.container}`}
      data-page={pathname.startsWith("/companies") ? "companies" : "default"}
    >
      <Provider store={storeRef.current}>
        <SessionProvider session={session}>
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}>
            <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-right' } }}>
              <ErrorProvider>
                {children}
              </ErrorProvider>
            </ChakraProvider>
          </GoogleOAuthProvider>
        </SessionProvider>
      </Provider>
      <PlausibleAnalytics />
      <UmamiAnalytics />
      {/* <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${ENVIRONMENT_CONFIGS.misc.googleTagManagerId}" height="0" width="0" style="display: none; visibility: hidden;"></iframe>`,
        }}
      />
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${ENVIRONMENT_CONFIGS.misc.googleTagManagerId}');
      `}
      </Script> */}
    </div>
  );
}
